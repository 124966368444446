<template>
  <v-dialog v-model="dialog" max-width="1000" persistent>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-card-title>Add User</v-card-title>
        <v-divider></v-divider>
        <div style="max-height: 500px; overflow-y: auto">
          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field v-model="username" :rules="usernameRules" label="Username" required></v-text-field>
                <v-text-field v-model="password" :rules="passwordRules" label="Password" required type="password"></v-text-field>
                <v-text-field v-model="first_name" :rules="first_nameRules" label="First Name" required></v-text-field>
                <v-text-field v-model="last_name" label="Last Name"></v-text-field>
                <v-text-field v-model="email" :rules="emailRules" label="Email" required></v-text-field>
                <v-text-field v-model="customer_no_" label="Customer No."></v-text-field>
                <v-text-field v-model="phone" label="Phone"></v-text-field>
              </v-col>
              <v-col>
                <v-radio-group v-model="user_type" :rules="user_typeRules">
                  <template v-slot:label>
                    <div>User Type</div>
                  </template>
                  <v-radio value="admin">
                    <template v-slot:label>
                      <div>Admin</div>
                    </template>
                  </v-radio>
                  <v-radio value="user">
                    <template v-slot:label>
                      <div>User</div>
                    </template>
                  </v-radio>
                  <v-radio value="customer">
                    <template v-slot:label>
                      <div>Customer</div>
                    </template>
                  </v-radio>
                </v-radio-group>
                <v-radio-group v-model="user_level" :rules="user_levelRules">
                  <template v-slot:label>
                    <div>User Level</div>
                  </template>
                  <v-radio value="sa">
                    <template v-slot:label>
                      <div>System Administrator</div>
                    </template>
                  </v-radio>
                  <v-radio value="admin">
                    <template v-slot:label>
                      <div>Admin</div>
                    </template>
                  </v-radio>
                  <v-radio value="standard">
                    <template v-slot:label>
                      <div>Standard</div>
                    </template>
                  </v-radio>
                  <v-radio value="sales">
                    <template v-slot:label>
                      <div>Sales</div>
                    </template>
                  </v-radio>
                  <v-radio value="scm">
                    <template v-slot:label>
                      <div>S C M</div>
                    </template>
                  </v-radio>
                  <v-radio value="wh">
                    <template v-slot:label>
                      <div>Warehouse</div>
                    </template>
                  </v-radio>
                  <v-radio value="area">
                    <template v-slot:label>
                      <div>Area</div>
                    </template>
                  </v-radio>
                  <v-radio value="customer">
                    <template v-slot:label>
                      <div>Customer</div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-card-text>
        </div>

        <v-card-actions class="align-end">
          <v-btn :disabled="!valid" color="primary" @click="saveData()">submit</v-btn>
          <v-btn @click="reset">clear</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="hide">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      valid: true,
      first_name: "",
      last_name: "",
      user_type: "",
      user_level: "",
      email: "",
      username: "",
      customer_no_: "",
      area: "",
      phone: "",
      password: "",

      first_nameRules: [(v) => !!v || "First Name is required"],
      user_typeRules: [(v) => !!v || "User Type is required"],
      user_levelRules: [(v) => !!v || "User Level is required"],
      emailRules: [(v) => !!v || "E-mail is required", (v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],
      usernameRules: [(v) => !!v || "Username is required"],
      passwordRules: [(v) => !!v || "Password is required"],

      fnCallback: null,
    };
  },

  watch: {
    dialog(val, oldVal) {
      if (val == false && oldVal == true) {
        this.$emit("onClose");
      }
    },
  },

  methods: {
    show(fnCallback) {
      this.dialog = true;
      this.fnCallback = fnCallback;
    },
    hide() {
      this.dialog = false;
      this.$emit("on-close");
      console.log("Close");
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    saveData() {
      const data = {
        first_name: this.first_name,
        last_name: this.last_name,
        user_type: this.user_type,
        user_level: this.user_level,
        email: this.email,
        username: this.username,
        customer_no_: this.customer_no_,
        area: this.area,
        phone: this.phone,
        password: this.password,
      };
      this.$emit("before-save", data);

      this.validate();
      var d = this.$refs.form.validate();

      if (d == true) {
        this.showLoadingOverlay(true);
        var formData = new FormData();
        formData.append("first_name", this.first_name);
        formData.append("last_name", this.last_name);
        formData.append("user_type", this.user_type);
        formData.append("user_level", this.user_level);
        formData.append("email", this.email);
        formData.append("username", this.username);
        formData.append("customer_no_", this.customer_no_);
        formData.append("area", this.area);
        formData.append("phone", this.phone);
        formData.append("password", this.password);
        this.$axios
          .post("user/save", formData)
          .then((res) => {
            this.showLoadingOverlay(false);
            var resData = res.data;
            if (resData.status == "success") {
              if (resData.status == "success") {
                this.showAlert(resData.status, resData.message);
                this.hide();
              } else {
                this.showAlert(resData.status, resData.message);
              }
            } else {
              this.showAlert(resData.status, resData.message);
            }
            this.$emit("after-save", resData.data);
          })
          .catch((error) => {
            this.showLoadingOverlay(false);
            this.axiosErrorHandler(error);
            this.$emit("after-save", null);
          });
      }
    },
  },
};
</script>
